.MuiOutlinedInput-notchedOutline {
    border-color: #FFF !important;
  }
  
  .MuiFilledInput-root {
    border-bottom: 1px solid #FFF !important;
    color: white !important;
  }
  
  .MuiInput-root {
    border-bottom: 1px solid #FFF !important;
    color: white !important;
  }

  .error-message {
    color: white;
    font-size: 12px;
    margin-top: 10px;
  }


  .formRoot {
    border: 1px solid white;
    border-radius: 5px;
    margin: 0 50px;
    padding: 50px 0;
  }